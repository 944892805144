// NYXNoCode.js
import React from 'react';
import { Link } from 'react-router-dom';
import NYXSymbol from './img/NYX_NoCode_symbol.png';
import NYXLogo from './img/NYX_noCode_Combined.png';
import NYXNoCode2 from './img/NYXNoCode.png';
import './NYXNoCode.css';

const NYXNoCode = () => (
    <div className="nyx-nocode">
        <div className="header">
            <img src={NYXLogo} alt="NYX NoCode" className="hero-img" />
            <h1 className="headerTitle">NYX NoCode</h1>
        </div>
        <div >
            <div className="story">
                <p>
                    NYX NoCode was named after Nyx, the ancient Greek Goddess of the Night, a powerful and mysterious deity who embodies the beauty and depth of the night sky. Just like the goddess, NYX NoCode brings a touch of magic and elegance to the world of web creation.
                </p>
                <p>
                    Created by Karen Kilroy and included free for File Baby subscribers, NYX NoCode was designed to empower users to create stunning web pages and simple software with ease. By harnessing the power of no-code technology, NYX NoCode enables anyone, regardless of their technical background, to build visually appealing and functional websites.
                </p>
                <img src={NYXNoCode2} alt="NYX NoCode, Goddess of the Night, Creator of Beautiful, Functional Web Applications" className="inset-image" />

                <p>
                    Through its intuitive interface and powerful features, NYX NoCode is here to help you bring your vision to life, making web creation accessible and enjoyable for everyone.
                </p>
                <h2>Pioneering a New Way of Software Development</h2>
                <p>NYX NoCode is revolutionizing software development with its innovative prompt-based interface, designed to make application creation accessible to everyone. Users start with simple prompts, guiding them through the initial stages of building web pages or applications. As they gain experience, they can develop more sophisticated prompts for complex business needs such as project management, CRM, and e-commerce.</p>
                <p>NYX NoCode's growing prompt library promises a rich collection of both NYX NoCode-supplied and community-generated prompts in the near future. This library will facilitate idea sharing and inspire users to explore new possibilities. Custom branding elements can also be easily incorporated into applications to maintain a unique corporate identity.</p>
                <p>NYX NoCode supports multiple languages for both prompts and output, making it ideal for global markets. Its scalability ensures that applications remain robust and secure as businesses grow without requiring extensive re-coding efforts. With NYX NoCode, users are encouraged to think creatively and push boundaries—if you have an idea, just create it.</p>
                <p>By enabling startups to rapidly prototype ideas and develop innovative solutions as they see fit, NYX NoCode is set to accelerate innovation in the startup ecosystem.</p>
                <p>
                    NYX NoCode integrates seamlessly with <a href={"https://file.baby"}>File Baby</a>, a digital provenance platform that provides secure and efficient file management solutions. This integration allows users to easily incorporate images, documents, and other media into their web pages, enhancing their online presence.
                </p>
            </div>
        </div>
        <div className="container">
            <img src={NYXSymbol} alt="NYX NoCode Symbol" className="footerImage" />
            <ul className="videoList">
                <li className="videoItem"><a href="https://claimed.at.file.baby/filebabyblob/karen@knowbots.org/1720104031732-generated-page.html" target="_blank" rel="noreferrer">NYX NoCode Demo Videos</a></li>
                <li className="videoItem"><a href="https://claimed.at.file.baby/filebabyblob/karen@knowbots.org/1720011630240-generated-page.html" target="_blank" rel="noreferrer">How to Claim and Upload a Video to NYX NoCode</a></li>
                <li className="videoItem"><a href="https://claimed.at.file.baby/filebabyblob/karen@knowbots.org/1720009487467-generated-page.html" target="_blank" rel="noreferrer">How to Create a Simple Beatbox with NYX NoCode</a></li>
                <li className="videoItem"><a href="https://claimed.at.file.baby/filebabyblob/karen@knowbots.org/1719988679472-generated-page.html" target="_blank" rel="noreferrer">How to Cat Slide Show Video with C2PA Verify Button</a></li>
                <li className="videoItem"><a href="https://claimed.at.file.baby/filebabyblob/karen@knowbots.org/1719986674168-generated-page.html" target="_blank" rel="noreferrer">How to Use NYX NoCode - Doggies</a></li>
            </ul>
            <div className="catalogLinkContainer">
                <Link to="/nyx-catalog" className="catalogLink">
                    <img src={NYXSymbol} alt="NYX Catalog" className="catalogImage" />
                    <div className="catalogTextContainer">
                        <p className="catalogDescription">Discover more about NYX NoCode and explore a variety of examples of interactive web pages that you create yourself using only a prompt. Click here to dive in!</p>
                    </div>
                </Link>
            </div>
        </div>
    </div>
);

export default NYXNoCode;
