import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";

const Navbar = () => {
    const [menuActive, setMenuActive] = useState(false);
 //   const [dropdownActive, setDropdownActive] = useState(false);
    let navigate = useNavigate();

    const toggleMenu = () => {
        setMenuActive(!menuActive);
    };

    /*const toggleDropdown = () => {
        setDropdownActive(!dropdownActive);
    };
*/
    const handleNavigation = (path) => {
        navigate(path);
        setMenuActive(false);
    };

    return (
        <section className="navbar">

            <div className="menu-icon" onClick={toggleMenu}>
                {/* Hamburger Icon */}
                {menuActive ? (
                    <span>X</span> /* Style this div as an 'X' icon */
                ) : (
                    <div>
                        <div></div>
                        <div></div>

                    </div> /* Hamburger lines */
                )}
            </div>
            <div className={`menu ${menuActive ? 'active' : ''}`}>
                <ul className={`nav-list ${menuActive ? 'active' : ''}`}>
                    <li className={"nav-item"}><Link to="/" onClick={() => handleNavigation('/')}>Home</Link></li>

                    <li className={"nav-item"}><Link to="NYX-NoCode" onClick={() => handleNavigation('/')}>How it Works</Link></li>

                    {/* ... other menu items */}

                    <li className={"nav-item"}>
                    <Link to="NYX-Catalog" onClick={() => handleNavigation('NYX-Catalog')}>NoCode Examples</Link>
                    </li>
                    <li className={"nav-item"}>
                        <Link to="NYXNoCodeWhitePaper" onClick={() => handleNavigation('WhitePaper')}>White Paper</Link>
                    </li>



                </ul>
            </div>

        </section>
    );
};

export default Navbar;
