import React from "react";
import NYXLogo from "./img/NYX_NoCode-Home.png";
import HeroSection from "./HeroSection";

function HomePage() {
    return (
        <div className="hero">
                <img src={NYXLogo} alt="NYX NoCode" />
     <HeroSection />
        </div>
);
}

export default HomePage;
