import React, { useState } from 'react';
import Chatbot from "./Chatbot";

const Footer = () => {
    const [, setFilterCriteria] = useState({});

    // Function to handle filter criteria changes
    const handleFilterCriteriaChange = (newCriteria) => {
        setFilterCriteria(newCriteria);
    };

    return (
        <section>

            <div className="footer">

                <div>
                    <Chatbot setFilterCriteria={handleFilterCriteriaChange} />
                </div>
                <p>
                    &copy;Copyright 2024, NYX NoCode, All Rights Reserved
                </p>

            </div>
        </section>
    );
};

export default Footer;
