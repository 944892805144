// NyxCatalog.js
import React from 'react';
import './NyxCatalog.css'; // Make sure to create this CSS file as shown earlier
import Hero from './img/NYX_catalog-header.png';
const examples = [
    { title: "1990s Web Page", link: "https://claimed.at.file.baby/filebabyblob/karen@knowbots.org/1720277570532-generated-page.html" },
    { title: "Simple 3D Image Generator", link: "https://claimed.at.file.baby/filebabyblob/kilroy@uark.edu/1720816241796-generated-page.html" },
    { title: "4 Color Drawing Pad", link: "https://claimed.at.file.baby/filebabyblob/karen@knowbots.org/1720579994285-generated-page.html" },
    { title: "Space Alien Fashion Show", link: "https://claimed.at.file.baby/filebabyblob/kilroy@uark.edu/1720877840160-generated-page.html" },
    { title: "Space Alien Ladies", link: "https://claimed.at.file.baby/filebabyblob/kilroy@uark.edu/1720874766691-generated-page.html" },
    { title: "Space Alien Ladies 2", link: "https://claimed.at.file.baby/filebabyblob/kilroy@uark.edu/1720635903085-generated-page.html" },
    { title: "Space Alien Luncheonette Album", link: "https://claimed.at.file.baby/filebabyblob/kilroy@uark.edu/1720873011018-generated-page.html" },
    { title: "Space Alien Luncheonette Band Bios and Song List", link: "https://claimed.at.file.baby/filebabyblob/kilroy@uark.edu/1720873383343-generated-page.html" },
    { title: "Bubbles the Kitten", link: "https://claimed.at.file.baby/filebabyblob/kilroy@uark.edu/1720243698578-generated-page.html" },
    { title: "Calorie Counter", link: "https://claimed.at.file.baby/filebabyblob/karen@knowbots.org/1720414593126-generated-page.html" },
    { title: "Delhi, India/Bentonville, Arkansas 24 Hour Clock", link: "https://claimed.at.file.baby/filebabyblob/karen@knowbots.org/1720481179919-generated-page.html" },
    { title: "Diverse Pink Pretty Dolls and Mainframes", link: "https://claimed.at.file.baby/filebabyblob/karen@knowbots.org/1720879309564-generated-page.html" },
    { title: "Dog Show Slideshow", link: "https://claimed.at.file.baby/filebabyblob/karen@knowbots.org/1720413876509-generated-page.html" },
    { title: "Fantasy History of NYX NoCode", link: "https://claimed.at.file.baby/filebabyblob/karen@knowbots.org/1720366830034-generated-page.html" },
    { title: "Fancy Beatbox", link: "https://claimed.at.file.baby/filebabyblob/karen%40knowbots.org%2F1720009075010-generated-page.html" },
    { title: "Find the Face on the Flower (Folding Slides)", link: "https://claimed.at.file.baby/filebabyblob/kilroy@uark.edu/1719980129369-generated-page.html" },
    { title: "Gallery of the Stars", link: "https://claimed.at.file.baby/filebabyblob/karen@knowbots.org/1720436940696-generated-page.html" },
    { title: "How to Create a Cat Slide Show - Video with C2PA Verify Button", link: "https://claimed.at.file.baby/filebabyblob/karen@knowbots.org/1719988679472-generated-page.html" },
    { title: "How to Claim and Upload a Video to NYX NoCode", link: "https://claimed.at.file.baby/filebabyblob/karen@knowbots.org/1720011630240-generated-page.html" },
    { title: "How to Create a Simple Beatbox with NYX NoCode", link: "https://claimed.at.file.baby/filebabyblob/karen@knowbots.org/1720009487467-generated-page.html" },
    { title: "How to Find a Lost Phone", link: "https://claimed.at.file.baby/filebabyblob/karen@knowbots.org/1720377294978-generated-page.html" },
    { title: "How to Use NYX NoCode - Doggies", link: "https://claimed.at.file.baby/filebabyblob/karen@knowbots.org/1719986674168-generated-page.html" },
    { title: "Image Combiner", link: "https://claimed.at.file.baby/filebabyblob/kilroy@uark.edu/1720638967056-generated-page.html" },
    { title: "Interactive Dots LCD Simulation", link: "https://claimed.at.file.baby/filebabyblob/karen@knowbots.org/1719934533076-generated-page.html" },
    { title: "Interactive Harmonograph", link: "https://claimed.at.file.baby/filebabyblob/kilroy@uark.edu/1720711332770-generated-page.html" },
    { title: "Interactive Spinning Spiro Gallery", link: "https://claimed.at.file.baby/filebabyblob/kilroy@uark.edu/1720665905861-generated-page.html" },
    { title: "Interactive Spirograph-like Image Maker", link: "https://claimed.at.file.baby/filebabyblob/kilroy@uark.edu/1720806860727-generated-page.html" },
    { title: "Interactive Touch Tone Generator", link: "https://claimed.at.file.baby/filebabyblob/karen%40knowbots.org%2F1719945822685-generated-page.html" },
    { title: "Interactive Word Scramble Game", link: "https://claimed.at.file.baby/filebabyblob/karen@knowbots.org/1720044302984-generated-page.html" },
    { title: "July 4 Fireworks", link: "https://claimed.at.file.baby/filebabyblob/karen@knowbots.org/1720058388260-generated-page.html" },
    { title: "Looking Down on Shooting Stars", link: "https://claimed.at.file.baby/filebabyblob/karen@knowbots.org/1720415092387-generated-page.html" },
    { title: "Mainframe Meme", link: "https://claimed.at.file.baby/filebabyblob/karen@knowbots.org/1720479909507-generated-page.html" },
    { title: "NYX NoCode Demo Videos", link: "https://claimed.at.file.baby/filebabyblob/karen@knowbots.org/1720104031732-generated-page.html" },
    { title: "Official Page of Karen Kilroy", link: "https://claimed.at.file.baby/filebabyblob/karen@knowbots.org/1720050774242-generated-page.html" },
    { title: "One Word Prompt: Kittens", link: "https://claimed.at.file.baby/filebabyblob/kilroy@uark.edu/1720890492983-generated-page.html" },
    { title: "Overlay and Spin Spiros", link: "https://claimed.at.file.baby/filebabyblob/kilroy@uark.edu/1720640200419-generated-page.html" },
    { title: "Poem Presented with NYX NoCode", link: "https://claimed.at.file.baby/filebabyblob/kilroy@uark.edu/1719770817920-generated-page.html" },
    { title: "Pricing Spreadsheet", link: "https://claimed.at.file.baby/filebabyblob/karen@knowbots.org/1720052712042-generated-page.html" },
    { title: "Sad Little Sunset Artifacts", link: "https://claimed.at.file.baby/filebabyblob/kilroy@uark.edu/1720823055536-generated-page.html" },
    { title: "Sad Little Sunset Interactive", link: "https://claimed.at.file.baby/filebabyblob/kilroy@uark.edu/1720822272018-generated-page.html" },
    { title: "Sad Little Sunset Story", link: "https://claimed.at.file.baby/filebabyblob/kilroy@uark.edu/1720819764646-generated-page.html" },
    { title: "Simple Word Processor", link: "https://claimed.at.file.baby/filebabyblob/karen@knowbots.org/1720493855588-generated-page.html" },
    { title: "Song Lyrics from Alien Luncheonette: Black Hole Blues", link: "https://claimed.at.file.baby/filebabyblob/kilroy@uark.edu/1720873582648-generated-page.html" },
    { title: "Surprise Me! Interactive", link: "https://claimed.at.file.baby/filebabyblob/kilroy@uark.edu/1720704284627-generated-page.html" },
    { title: "The Future of Software is Generative", link: "https://claimed.at.file.baby/filebabyblob/kilroy%40uark.edu%2F1720824104462-generated-page.html" },
    { title: "The Most Beautiful Fairy", link: "https://claimed.at.file.baby/filebabyblob/karen@knowbots.org/1720375471817-generated-page.html" },
    { title: "Time Machine Animation", link: "https://claimed.at.file.baby/filebabyblob/karen@knowbots.org/1719965246012-generated-page.html" },
    { title: "Two Word Prompt: Hello World", link: "https://claimed.at.file.baby/filebabyblob/karen@knowbots.org/1720892521312-generated-page.html" },
    { title: "Video by Arno Coenen with C2PA Verify Button", link: "https://claimed.at.file.baby/filebabyblob/karen@knowbots.org/1719962304442-generated-page.html" },
    { title: "Dancing Dots Interactive Animation", link: "https://claimed.at.file.baby/filebabyblob/karen@knowbots.org/1720904973131-generated-page.html" },
    { title: "Simple HTML Editor", link: "https://claimed.at.file.baby/filebabyblob/karen@knowbots.org/1720978791488-generated-page.html" },
    { title: "Simple Slide Show Maker", link: "https://claimed.at.file.baby/filebabyblob/karen@knowbots.org/1720979498169-generated-page.html" },
    { title: "Simple Slide Show Maker 2", link: "https://claimed.at.file.baby/filebabyblob/karen%40knowbots.org%2F1720980545689-generated-page.html" },
    { title: "Pitch Deck", link: "https://claimed.at.file.baby/filebabyblob/karen@knowbots.org/1721099119226-generated-page.html" },
    { title: "Lyrics to NYX NoCode Theme Song: Glitterface", link: "https://claimed.at.file.baby/filebabyblob/kilroy@uark.edu/1721130290359-generated-page.html" },
    { title: "Interactive: Unexpected Effects of a Time Machine", link: " https://claimed.at.file.baby/filebabyblob/karen@knowbots.org/1721218268807-generated-page.html" },
    { title: "Image Annotator: Marjorie's Childlike Wonder", link: "https://claimed.at.file.baby/filebabyblob/karen@knowbots.org/1721304303977-generated-page.html" },
    { title: "White Paper: Enhancing Digital Content Management with File Baby and NYX NoCode", link: "https://claimed.at.file.baby/filebabyblob/karen@knowbots.org/1721380541259-generated-page.html" },
    { title: "Space Alien Coffee Time", link: "https://claimed.at.file.baby/filebabyblob/karen@knowbots.org/1721333136664-generated-page.html" },
    { title: "Surprise!", link: "https://claimed.at.file.baby/filebabyblob/karen@knowbots.org/1721331888268-generated-page.html" },
    { title: "Clipboard Paste Image Viewer/Save to PNG!", link: "https://claimed.at.file.baby/filebabyblob/kilroy@uark.edu/1721474359942-generated-page.html" },
    { title: "Clipboard Paste or Drag and Drop Image Viewer (no save)", link: "https://claimed.at.file.baby/filebabyblob/kilroy@uark.edu/1721474075142-generated-page.html" },
    { title: "Audio Recorder/Stop Saves & Downloads wav", link: "https://claimed.at.file.baby/filebabyblob/karen@knowbots.org/1721494558593-generated-page.html" },
    { title: "Full-Screen Manual Advance Art Slides", link: "https://claimed.at.file.baby/filebabyblob/kilroy@uark.edu/1721503535337-generated-page.html" },
    { title: "Surprised Faces Slide Show", link: "https://claimed.at.file.baby/filebabyblob/kilroy@uark.edu/1721507268520-generated-page.html" },
    { title: "Activism: Harmony Doe's Clean Green Neighborhood", link: "https://claimed.at.file.baby/filebabyblob/kilroy@uark.edu/1721526986005-generated-page.html" },
    { title: "Floating Stars", link: "https://claimed.at.file.baby/filebabyblob/karen@knowbots.org/1721529432358-generated-page.html" },
    { title: "Scientific Programming Trick", link: "https://claimed.at.file.baby/filebabyblob/karen@knowbots.org/1721530793406-generated-page.html" },
    { title: "Interactive Dog Years vs Human Years Plot Chart", link: "https://claimed.at.file.baby/filebabyblob/karen@knowbots.org/1721552626030-generated-page.html" },
    { title: "Activism: Harmony Doe's Clean Green Teaser Page", link: "https://claimed.at.file.baby/filebabyblob/kilroy@uark.edu/1721517260582-generated-page.html" },
    { title: "Activism: Election Support Calculator", link: "https://claimed.at.file.baby/filebabyblob/kilroy@uark.edu/1721556721207-generated-page.html" },
    { title: "Sea Turtles", link: "https://claimed.at.file.baby/filebabyblob/kilroy@uark.edu/1721555835378-generated-page.html" },
    { title: "Sharing Code: Formatted Python example", link: "https://claimed.at.file.baby/filebabyblob/kilroy@uark.edu/1721582607118-generated-page.html" },
    { title: "JSON Inspector", link: "https://claimed.at.file.baby/filebabyblob/kilroy@uark.edu/1721582973261-generated-page.html" },
    { title: "NYX NoCode Theme Song Instrumental Version: Glitterface", link: "https://claimed.at.file.baby/filebabyblob/karen@knowbots.org/1721602432168-generated-page.html" },
    { title: "1 Minute Video Maker (audio+images)", link: "https://claimed.at.file.baby/filebabyblob/karen@knowbots.org/1721728334042-generated-page.html" },
    { title: "Birthday Card", link: "https://claimed.at.file.baby/filebabyblob/kilroy@uark.edu/1721996574257-generated-page.html" },
    { title: "Invitation", link: "https://claimed.at.file.baby/filebabyblob/kilroy@uark.edu/1722004663286-generated-page.html" },
    { title: "EZ Sweet 1.1", link: "https://claimed.at.file.baby/filebabyblob/kilroy@uark.edu/1722005163982-generated-page.html" },
    { title: "Song About NYX NoCode by NYX NoCode", link: "https://claimed.at.file.baby/filebabyblob/kilroy@uark.edu/1722102756580-generated-page.html" },
    { title: "Easter Surprise Art Slides", link: "https://claimed.at.file.baby/filebabyblob/kilroy@uark.edu/1722119766620-generated-page.html" },
    { title: "Pop Art Fruit Slides", link: "https://claimed.at.file.baby/filebabyblob/kilroy@uark.edu/1722120909949-generated-page.html" },
    { title: "Mischievous Robot Slides", link: "https://claimed.at.file.baby/filebabyblob/kilroy@uark.edu/1722120449004-generated-page.html" },
    { title: "Follow the Bouncing Ball", link: "https://claimed.at.file.baby/filebabyblob/karen@knowbots.org/1722268291895-generated-page.html" },
    { title: "Slides: Artifacts of Slide Show Planning", link: "https://claimed.at.file.baby/filebabyblob/karen@knowbots.org/1722268291895-generated-page.html" },
    { title: "Game: Fidget Widgets", link: "https://claimed.at.file.baby/filebabyblob/kilroy@uark.edu/1722296111949-generated-page.html" },
    { title: "Python Tips from NYX NoCode", link: "https://claimed.at.file.baby/filebabyblob/kilroy@uark.edu/1722300999127-generated-page.html" },
    { title: "Meme: Elephants Never Forget", link: "https://claimed.at.file.baby/filebabyblob/kilroy@uark.edu/1722302086387-generated-page.html" },
    { title: "One Word Prompt: Daydreaming", link: "https://claimed.at.file.baby/filebabyblob/kilroy@uark.edu/1722302975585-generated-page.html" },
    { title: "Lillies of the Valley", link: "https://claimed.at.file.baby/filebabyblob/kilroy@uark.edu/1722303933832-generated-page.html" },
    { title: "One Word Prompt: Roses", link: "https://claimed.at.file.baby/filebabyblob/kilroy@uark.edu/1722304117890-generated-page.html" },
    { title: "Prompt: The Most Wonderful Thing", link: "https://claimed.at.file.baby/filebabyblob/kilroy@uark.edu/1722305288925-generated-page.html" },
    { title: "Banania", link: "https://claimed.at.file.baby/filebabyblob/kilroy@uark.edu/1722352368627-generated-page.html" },
    { title: "My Name in Neon Lights", link: "https://claimed.at.file.baby/filebabyblob/kilroy@uark.edu/1722569749439-generated-page.html" },
    { title: "Abstract Art: Surprise E", link: "https://claimed.at.file.baby/filebabyblob/kilroy@uark.edu/1722571209461-generated-page.html" },
    { title: "Eliza", link: "https://claimed.at.file.baby/filebabyblob/kilroy%40uark.edu%2F1722903400956-generated-page.html" },
    { title: "Jenny's Towing Company", link: "https://claimed.at.file.baby/filebabyblob/kilroy@uark.edu/1722865668764-generated-page.html" },
    { title: "AI Archives Slide Show", link: "https://claimed.at.file.baby/filebabyblob/karen@knowbots.org/1723942220435-generated-page.html" },

];
examples.sort((a, b) => a.title.localeCompare(b.title));

const NyxCatalog = () => {
    return (
        <div className="nyx-catalog hero">
            <img src={Hero} alt={"NYX Catalog"} />
            <h1>Your ideas come to life <em>in seconds</em> using NYX NoCode</h1>
            <p className={"whitefont"}>Create any of these yourself–and so much more–with your own content NYX NoCode. The future of software is generative! With NYX NoCode, you can create simple tools on demand.</p>
            <p className={"whitefont"}>Each of these examples was created with a short prompt in less than 30 seconds.</p>
            <p className={"whitefont"}>While these are fun examples, your assets can be securely available for 30 second interactive web application creation. Enterprise quotes are available on request.</p>

            <div className="catalog">
                {examples.map((example, index) => (
                    <div key={index} className="example">
                        <h2>{example.title}</h2>
                        <a href={example.link} target="_blank" rel="noopener noreferrer">View Example</a>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default NyxCatalog;
