// HeroSection.js
import React from 'react';

const HeroSection = () => (
    <div><p>For more information on how NYX NoCode can transform your world, <a href="mailto:karen@knowbots.org" className="contact-link">contact us</a>
    </p>
    </div>
);


export default HeroSection;



