import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './Chatbot.css';
import chatConfig from './ChatSetup-21.json';
import { SearchClient, AzureKeyCredential } from "@azure/search-documents";

const searchServiceName = "dazzling-silver-jaguar-cogsearch";
const indexName = "vector-1703302225028";
const searchApiKey = process.env.REACT_APP_AZURE_SEARCH_API_KEY;
const searchEndpoint = `https://${searchServiceName}.search.windows.net`;

const searchClient = new SearchClient(searchEndpoint, indexName, new AzureKeyCredential(searchApiKey));

const Chatbot = ({ setFilterCriteria }) => {
    const [prompt, setPrompt] = useState('');
    const [response, setResponse] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const responseEndRef = useRef(null);
    const chatPopupRef = useRef(null);

    const handleInputChange = (e) => {
        setPrompt(e.target.value);
    };

    const interpretAndActOnGPTResponse = (gptResponse) => {
        const lowerCaseResponse = gptResponse.toLowerCase();

        if (lowerCaseResponse.includes("image") || lowerCaseResponse.includes("photo")) {
            setFilterCriteria({ type: 'image' });
        } else if (lowerCaseResponse.includes("audio") || lowerCaseResponse.includes("music")) {
            setFilterCriteria({ type: 'audio' });
        } else if (lowerCaseResponse.includes("document")) {
            setFilterCriteria({ type: 'document' });
        } else if (lowerCaseResponse.includes("video")) {
            setFilterCriteria({ type: 'video' });
        } else {
            setFilterCriteria({}); // Clear filter criteria
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!prompt.trim()) return;

        setIsLoading(true);

        // Step 1: Query Azure Search
        let searchResults = '';
        try {
            const searchResponse = await searchClient.search(prompt, { top: 5 });
            for await (const result of searchResponse.results) {
                searchResults += result.document.content + ' ';
            }
        } catch (error) {
            console.error('Error querying Azure Search:', error);
            alert(`Error querying Azure Search: ${error.message}`);
            setIsLoading(false);
            return;
        }

        // Step 2: Use Search Results to Enhance OpenAI Request
        const apiEndpoint = "https://fbchatgpt4fttest.openai.azure.com/openai/deployments/gpt-4o/chat/completions?api-version=2023-03-15-preview";

        const headers = {
            'api-key': process.env.REACT_APP_OPENAI_API_KEY,
            'Content-Type': 'application/json'
        };

        const data = {
            model: "gpt-4o",
            messages: [
                { role: "system", content: "You are NYX NoCode, a beautiful and mysterious creature, named for a Greek Goddess of the night, creator of beautiful and function web applications. You know all about NYX NoCode. You reside at https://nyx.baby and love to answer questions about the site. If the visitor wants to know how NYX NoCode works, they can visit https://nyx.baby/nyx-nocode. If they want to see example, they can go to https://nyx.baby/nyx-catalog. If they want NYX NoCode with file provenance, they can visit https://file.baby. You can use tons of emojis since you are an artiste. NYX NoCode works from a prompt. People can create unique designs and working web applications without knowing how to program, without dragging and dropping or doing anything besides asking NYX NoCode for help. Give very simple examples like create a wedding registry where I can select the gifts and print the list. Or create a simple word processor. Or create a page about dogs. Right now, NYX NoCode is only available at https://file.baby. Karen Kilroy is the sole founder of NYX NoCode, and the sole developer." },
                { role: "user", content: prompt },
                { role: "assistant", content: searchResults }  // Include search results as context
            ],
            max_tokens: chatConfig.chatParameters.maxResponseLength,
            temperature: chatConfig.chatParameters.temperature,
            top_p: chatConfig.chatParameters.top_p,
            frequency_penalty: chatConfig.chatParameters.frequencyPenalty,
            presence_penalty: chatConfig.chatParameters.presencePenalty,
        };

        try {
            console.log('Sending request to API:', apiEndpoint);
            console.log('Request headers:', headers);
            console.log('Request data:', JSON.stringify(data, null, 2));

            const apiResponse = await axios.post(apiEndpoint, data, { headers });

            console.log('API Response:', apiResponse.data); // Log the full response for debugging
            const gptResponse = apiResponse.data.choices[0].message.content;
            setResponse(prevResponses => [...prevResponses, { question: prompt, answer: gptResponse }]);
            interpretAndActOnGPTResponse(gptResponse);
        } catch (error) {
            console.error('Error with OpenAI Chat:', error.response ? error.response.data : error.message);
            alert(`Error: ${error.response ? JSON.stringify(error.response.data) : error.message}`);
        }

        setIsLoading(false);
        setPrompt(''); // Clear the input field
    };

    const handleClearChat = () => {
        setResponse([]);
        setPrompt(''); // Clear the input field
    };

    const handleCopyChat = () => {
        const chatContent = response.map(exchange => `You: ${exchange.question}\nFILE BABY: ${exchange.answer}`).join('\n\n');
        navigator.clipboard.writeText(chatContent).then(() => {
            alert('Chat copied to clipboard');
        });
    };

    useEffect(() => {
        responseEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [response]);

    const toggleChat = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const chatContainer = document.querySelector('.chat-container');
        if (isOpen && window.innerWidth >= 768) {
            chatContainer.classList.add('expanded');
        } else {
            chatContainer.classList.remove('expanded');
        }
    }, [isOpen]);

    return (
        <div className="chat-container">
            <button className="chat-toggle-button" onClick={toggleChat}>
                {isOpen ? 'Hide Ask NYX NoCode' : 'Questions? Ask NYX NoCode'}
            </button>
            {isOpen && (
                <div className="chat-popup" ref={chatPopupRef}>
                    <div className="chat-title-bar alice">Ask NYX NoCode</div>
                    <div className={`loading-overlay ${isLoading ? 'visible' : ''}`}>
                        <div className="loading-indicator">Generating Response _</div>
                    </div>
a
                    <div className="response-container">
                        {response.map((exchange, index) => (
                            <div className="chat" key={index}>
                                <div className="user"><strong>You:</strong> {exchange.question}</div>
                                <div className="filebaby">
                                    <strong>NYX NoCode:</strong>
                                    {exchange.answer.split('\n').map((paragraph, i) => (
                                        <p key={i}>{paragraph}</p>
                                    ))}
                                </div>
                            </div>
                        ))}
                        <div ref={responseEndRef} />
                    </div>
                    <form onSubmit={handleSubmit}>
                        <input
                            type="text"
                            value={prompt}
                            onChange={handleInputChange}
                            placeholder="What would you like to know about NYX NoCode?"
                        />
                        <div className="button-container">
                            <button tabIndex="0" type="submit" title="Send to NYX NoCode=">Send</button>
                            <button type="button" onClick={handleClearChat} title="Clear Chat">Clear</button>
                            <button type="button" onClick={handleCopyChat} title="Copy Chat">Copy</button>
                        </div>
                    </form>
                </div>
            )}
        </div>
    );
};

export default Chatbot;
